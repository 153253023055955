// Entry point for the build script in your package.json
import "./libs/jquery";
import "@hotwired/turbo-rails"
import * as bootstrap from "bootstrap"
import "trix"
import "@rails/actiontext"
import "./controllers"

document.addEventListener("turbo:load", () =>{
  $(".has-tooltip").tooltip()
})